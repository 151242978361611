<template>
  <div>
    <mly-header></mly-header>
    <section class="center-content">

      <p>{{company}}是一家专业从事移动互联网软件研发、手机网络游戏发行、运营与推广的创新型科技公司。创始团队和核心成员在无线互联网领域和手机游戏领域沉淀多年，积累了丰富的市场渠道资源和经验。曾成功运营多款基于android、ios知名手机游戏。
      </p>

    </section>
    <mly-footer></mly-footer>
  </div>
</template>
<script>
export default {
    computed: {
        //当前运行在哪个域名上
        domain() {
            if (this.CONST.isDev) {
                return "127.0.0.1";
            }

            var __domain = document.domain;
            var __domain_array = __domain.split(".");
            if (__domain_array.length == 3) {
                __domain = __domain_array[1];
            } else if (__domain_array.length == 2) {
                __domain = __domain_array[0];
            }
            return __domain;
        },
        //公司名称
        company() {
            if (this.domain === "mlygame" || this.domain === "mlyol") {
                return "上海喵啰咿科技有限公司";
            } else {
                return "深圳市拓建源科技发展有限公司";
            }
        }
    }
};
</script>

<style scoped>
.center-content {
    text-align: center;
    width: 100%;
    height: 580px;
    background-image: url(~@/assets/triangle_left.png),
        url(~@/assets/triangle_right.png);
    background-repeat: no-repeat;
    background-position: 0 40%, right bottom;
    min-width: 1500px;
    user-select: none;
}

.center-content p {
    text-align: left;
    text-indent: 2em;
    color: #666666;
    font-size: 16px;
    line-height: 3;
    margin: 0 auto;
    padding-top: 250px;
    user-select: text;
    max-width: 700px;
}
</style>
